<template>
  <div class="home">
    <LoginBar @login-event="login" @logout-event="logout" ref="loginbar"></LoginBar>
    <div class="container mt-2 ">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <h3 class="text-center mt-2 mb-2"><a href="https://vka.mil.ru">Военно-космическая академия имени А.Ф.Можайского</a></h3>
            <h3 class="text-center mt-2 mb-2">5 факультет (сбора и обработки информации)</h3>
            <h3 class="text-center mt-2 mb-2">52 кафедра (технологий и средств геофизического обеспечения)</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 mx-auto">
            <h2 class="text-center mt-5 mb-4">Об олимпиаде</h2>
            <p>Для участия в олимпиаде необходимо предварительно зарегистрироваться. Регистрация доступна с 16.00 до 16.20 26 марта 2024 года (время Московское). </p>
            <p>Сама олимпиада будет проводиться 26 марта с 16.30 до 17.30 26 марта 2024 года (время Московское).</p>
            <p>На выполнение заданий олимпиады отводится 60 минут.</p>
            <h4 class="text-center mt-5 mb-3">Положение</h4>
            <p>Формат олимпиады тестовый. Конкурсанту предложены задания, включающие в себя вопросы с развернутыми, однозначными и множественными ответами. Оценивается правильность и скорость выполнения заданий.</p>
            <h4 class="text-center mt-5 mb-3">Итоги</h4>
            <p>После прохождения испытаний конкурсант попадает в общий рейтинг, где занимает определённую строчку в списке всех участников олимпиады. Победители и призёры награждаются дипломами.</p>
          </div>
        </div>
      </div>
      
      <div class="row" v-if="allowtest&&isLoggedIn">
        <h2>Доступные тесты</h2>
          <div  
            v-for="(item,index) in quizList"
            :key="index"
            class="card card-shadow me-3 mt-3 col"
            href="/quiz/start/{{item.id}}" >
            <div class="card-header">
              <h4>{{item.title}}</h4>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary" @click="startQuiz(item.id)">Пройти тест</button>
            </div>
          </div>
        
      </div>
      <div v-if="!allowtest" class="col-md-8 mx-auto mt-5">
        
            <div  class="alert alert-info alert-dismissible fade show" role="alert" >
              <h2>Олимпиада еще не началась</h2> <span v-if="allowregister">Сейчас доступна <a href="#" @click="registerwindow">регистрация</a> пользователей</span>
            </div>
        
      </div>
      <div v-if="allowtest&&!isLoggedIn" class="col-md-8 mx-auto mt-5">
        
            <div  class="alert alert-info alert-dismissible fade show" role="alert" >
              <h2>Олимпиада уже началась <font-awesome-icon icon="fa-solid fa-clock" class="ms-2"/></h2> <span >Для прохождения тестов необходимо войти под своими учетными данными</span>
            </div>
        
      </div>
      
      

    </div>

    <div class="footer mt-5 pt-5 d-flex flex-column flex-sm-row justify-content-between container mb-5 text-center text-sm-left">

      <div class="mb-3 mb-sm-0 d-flex flex-column">
        <div>Контакты <a href="mailto:info@meteoquiz.ru">info@meteoquiz.ru</a></div>
      </div>
      <div>Copyright © 2024 | info@meteoquiz.ru </div>

    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import LoginBar from '../components/LoginBar.vue'

export default {
  name: "HomePage",
  data(){
    return {
      freeSpace: 0,
      quizList:[]
    }
  },
  components: {
    LoginBar
  },
  mounted() {
    this.loadQuiz();
  },
  methods:{
    ...mapMutations([
        // 'setActivePool',
        // 'setPools',
      ]),

    /**
     * Стартуем тест, но сначала проверяем залогинен ли пользователь
     */
    startQuiz(quiz_id){
      if (!this.isLoggedIn){
        this.$swal( { 
            title: 'Доступ запрещен',
            icon: 'error',
            text: "Для прохождения теста необходимо зарегистрироваться и войти в систему.",
          } );
        return;
      }
      this.$router.push('/quiz/'+quiz_id)
    },

    /**
     * Загружаем данные об операциях
     */
    async loadQuiz(){
      this.ajaxBlock = true;
      this.datatable = [];
      const response = await fetch(this.hostDebug+"/api/quiz", {
                headers: { "Content-Type": "application/json",
                          "Accept" : "application/json",
                          ...this.authHeader },
                method: "GET"
              });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data ){
          this.quizList = data;
        }
      } )
    },

    login(){
      console.log("login event");
    },

    logout(){
      console.log("logout event");
    },

    registerwindow(){
      // console.log("register event");
      this.$refs.loginbar.openRegisterWindow();
    }
    
  },
  computed:{
    ...mapGetters([
      'hostDebug',
      'isLoggedIn',
      'isAdmin',
      'authHeader',
      'allowtest',
      'allowregister'
    ]),
   
  },
};
</script>
